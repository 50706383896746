// import crypto from 'crypto';

// const crypto_orig_createHash = crypto.createHash;
// crypto.createHash = algorithm => crypto_orig_createHash(algorithm == "md4" ? "md5" : algorithm);

import React from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";

const Question = ({ number, text }) => {
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="h6" component="h2">
            Q{number}.
          </Typography>
          <Typography variant="body1" component="p">
            {text}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const App = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1">
          NLP/Assignments
        </Typography>
      </Grid>
      <Question number={1} text="What is NLP?" />
      <Question number={2} text="What is NLP?" />
      <Question number={3} text="What is NLP?" />
      <Question number={4} text="What is NLP?" />
      <Question number={5} text="What is NLP?" />
    </Grid>
  );
};
export default App;
