import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const SReflections = () => {
  const [question, setTopicName] = useState('');
  const [answer, setAnswer] = useState('');
  const [submittedAnswers, setSubmittedAnswers] = useState([]);

  useEffect(() => {
    const storedTopicName = localStorage.getItem('topicName');
    if (storedTopicName) {
      setTopicName(storedTopicName); // Set the topic name from local storage
    }
  }, []);
  

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Save the topic name in local storage
    localStorage.setItem('Ans', answer);
    
    setAnswer(''); // Clear the input field
};
  return (
    <form onSubmit={handleSubmit}>
    <div style={{ padding: '20px' }}>
      <h1>Student's Today</h1>
      <h2>{question || "No Question for Today."}</h2>
      <TextField
        label="Enter your answer"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        fullWidth
        multiline
        rows={4}
      />
      <br />
      <Button variant="contained" type="submit"  style={{ marginTop: '10px' }}>Submit</Button>
     
    </div>
    </form>
  );
};

export default SReflections;