// src/components/AdminPage.jsx
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

const DDescription = () => {
  const [topicName, setTopicName] = useState('');
  const [description, setDescription] = useState('');

  const handleUpdate = () => {
    // Save both topic name and description in local storage
    localStorage.setItem('topicName', topicName);
    localStorage.setItem('description', description);
    setTopicName(''); // Clear the input field
    setDescription(''); // Clear the input field
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Admin Page</h1>
      <TextField
        label="Topic Name"
        variant="outlined"
        value={topicName}
        onChange={(e) => setTopicName(e.target.value)}
        fullWidth
        style={{ marginBottom: '10px' }}
      />
      <TextField
        label="Update Description"
        variant="outlined"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
      />
      <Button variant="contained" onClick={handleUpdate} style={{ marginTop: '10px' }}>
        Update
      </Button>
    </div>
  );
};

export default DDescription;