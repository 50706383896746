import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Menu,
  MenuItem,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const Calender = () => {
  const [rows, setRows] = useState([
    { day: "Day", subjects: ["P1", "P2", "P3", "P4", "P5", "P6"] },
    { day: "Mon", subjects: ["sub", "sub", "sub", "sub", "sub", "sub"] },
    { day: "Tue", subjects: ["sub", "sub", "sub", "sub", "sub", "sub"] },
    { day: "Wed", subjects: ["sub", "sub", "sub", "sub", "sub", "sub"] },
    { day: "Thu", subjects: ["sub", "sub", "sub", "sub", "sub", "sub"] },
    { day: "Fri", subjects: ["sub", "sub", "sub", "sub", "sub", "sub"] },
    { day: "Sat", subjects: ["sub", "sub", "sub", "sub", "sub", "sub"] },
  ]);
  const [columns, setColumns] = useState(["P1", "P2", "P3", "P4", "P5", "P6"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [editing, setEditing] = useState(false);
  const [editedRows, setEditedRows] = useState(
    rows.map((row) => ({
      ...row,
      subjects: row.subjects.map((subject) => ({
        value: subject,
        editing: false,
      })),
    }))
  );

  const handleAddRow = () => {
    if (editedRows.length < 12) {
      setEditedRows([
        ...editedRows,
        {
          day: `Day ${editedRows.length + 1}`,
          subjects: Array(columns.length)
            .fill("sub")
            .map((subject) => ({ value: subject, editing: false })),
        },
      ]);
    }
  };

  const handleRemoveRow = () => {
    if (editedRows.length > 1) {
      setEditedRows(editedRows.slice(0, -1));
    }
  };

  const handleAddColumn = () => {
    if (columns.length < 12) {
      setColumns([...columns, `P${columns.length + 1}`]);
      setEditedRows(
        editedRows.map((row) => ({
          ...row,
          subjects: [...row.subjects, { value: "sub", editing: false }],
        }))
      );
    }
  };

  const handleRemoveColumn = () => {
    if (columns.length > 1) {
      setColumns(columns.slice(0, -1));
      setEditedRows(
        editedRows.map((row) => ({
          ...row,
          subjects: row.subjects.slice(0, -1),
        }))
      );
    }
  };

  const handleEdit = () => {
    setEditing(true);
    handleClose();
  };

  const handleSave = () => {
    setEditing(false);
    setRows(
      editedRows.map((row) => ({
        ...row,
        subjects: row.subjects.map((subject) => subject.value),
      }))
    );
    handleClose();
  };

  const handleCellBlur = (rowIndex, columnIndex) => {
    setEditedRows(
      editedRows.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            subjects: row.subjects.map((subject, subjectIndex) => {
              if (subjectIndex === columnIndex) {
                return { value: subject.value, editing: false };
              }
              return subject;
            }),
          };
        }
        return row;
      })
    );
  };

  const handleCellEdit = (rowIndex, columnIndex, newValue) => {
    setEditedRows(
      editedRows.map((row, index) => {
        if (index === rowIndex) {
          if (columnIndex === -1) {
            return { ...row, day: newValue };
          } else {
            return {
              ...row,
              subjects: row.subjects.map((subject, subjectIndex) => {
                if (subjectIndex === columnIndex) {
                  return { value: newValue, editing: true };
                }
                return subject;
              }),
            };
          }
        }
        return row;
      })
    );
  };

  return (
    <div className="time-table-container">
      <Paper className="time-table-paper">
        <Typography variant="h3" align="center" gutterBottom>
          Time Table
        </Typography>
        <Table className="time-table">
          <TableHead>
            <TableRow>
              {editing ? (
                <TableCell>
                  <input
                    type="text"
                    value={editedRows[0].day}
                    onChange={(e) => handleCellEdit(0, -1, e.target.value)}
                    onBlur={() => handleCellBlur(0, -1)}
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: "10px",
                      border: "none",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell>{editedRows[0].day}</TableCell>
              )}
              {editedRows[0].subjects.map((subject, columnIndex) => (
                <TableCell key={columnIndex}>
                  {editing ? (
                    <input
                      type="text"
                      value={subject.value}
                      onChange={(e) =>
                        handleCellEdit(0, columnIndex, e.target.value)
                      }
                      onBlur={() => handleCellBlur(0, columnIndex)}
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                    />
                  ) : (
                    subject.value
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {editedRows.slice(1).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell>
                  {editing ? (
                    <input
                      type="text"
                      value={row.day}
                      onChange={(e) =>
                        handleCellEdit(rowIndex + 1, -1, e.target.value)
                      }
                      onBlur={() => handleCellBlur(rowIndex + 1, -1)}
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                    />
                  ) : (
                    row.day
                  )}
                </TableCell>
                {row.subjects.map((subject, columnIndex) => (
                  <TableCell key={columnIndex}>
                    {editing ? (
                      <input
                        type="text"
                        value={subject.value}
                        onChange={(e) =>
                          handleCellEdit(
                            rowIndex + 1,
                            columnIndex,
                            e.target.value
                          )
                        }
                        onBlur={() => handleCellBlur(rowIndex + 1, columnIndex)}
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "10px",
                          border: "none",
                          borderRadius: "5px",
                          fontSize: "16px",
                        }}
                      />
                    ) : (
                      subject.value
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          variant="contained"
          onClick={handleClick}
          className="edit-button"
        >
          Edit Table
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="edit-menu"
        >
          <MenuItem onClick={handleAddRow}>Add Row</MenuItem>
          <MenuItem onClick={handleRemoveRow}>Remove Row</MenuItem>
          <MenuItem onClick={handleAddColumn}>Add Column</MenuItem>
          <MenuItem onClick={handleRemoveColumn}>Remove Column</MenuItem>
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleSave}>Save</MenuItem>
        </Menu>
      </Paper>
    </div>
  );
};
export default Calender;
