// import crypto from 'crypto';

// const crypto_orig_createHash = crypto.createHash;
// crypto.createHash = algorithm => crypto_orig_createHash(algorithm == "md4" ? "md5" : algorithm);

import * as React from "react";
import ReactDOM from "react-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BasicCards from "./BasicCards";
import Schedule from "./Schedule";
import Subjects from "./Subjects";
import Calender from "./Calender";
import Analysis from "./Analysis";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SubjectIcon from "@mui/icons-material/Subject";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AnalyticsSharpIcon from "@mui/icons-material/AnalyticsSharp";
const handleHomeClick = () => {
  ReactDOM.render(<BasicCards />, document.getElementById("itlfpages"));
};

const handleScheduleClick = () => {
  ReactDOM.render(<Schedule />, document.getElementById("itlfpages"));
};

const handleSubjectsClick = () => {
  ReactDOM.render(<Subjects />, document.getElementById("itlfpages"));
};

const handleCalenderClick = () => {
  ReactDOM.render(<Calender />, document.getElementById("itlfpages"));
};

const handleAnalysisClick = () => {
  ReactDOM.render(<Analysis />, document.getElementById("itlfpages"));
};

 

export const mainListItems = (
  <React.Fragment>
    <ListItemButton onClick={handleHomeClick}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>
    <ListItemButton onClick={handleScheduleClick}>
      <ListItemIcon>
        <ScheduleIcon />
      </ListItemIcon>
      <ListItemText primary="Schedule" />
    </ListItemButton>
    <ListItemButton onClick={handleSubjectsClick}>
      <ListItemIcon>
        <SubjectIcon />
      </ListItemIcon>
      <ListItemText primary="Subjects" />
    </ListItemButton>
    <ListItemButton onClick={handleCalenderClick}>
      <ListItemIcon>
        <CalendarMonthIcon />
      </ListItemIcon>
      <ListItemText primary="Calender" />
    </ListItemButton>
    <ListItemButton onClick={handleAnalysisClick}>
      <ListItemIcon>
        <AnalyticsSharpIcon />
      </ListItemIcon>
      <ListItemText primary="Analysis" />
    </ListItemButton>
  </React.Fragment>
);


export const secondaryListItems = (
  <>
  </>
);