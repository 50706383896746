import React from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import BasicCards from "./BasicCards";
const handleClick = () => {
  ReactDOM.render(<BasicCards />, document.getElementById("itlfpages"));
};
const Subjects = () => {
  return (
    <div id="content">
      <Grid container spacing={4} sx={{ maxWidth: 800 }}>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Subject 1
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleClick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Subject 2
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleClick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Subject 3
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleClick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Subject 4
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleClick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Subjects;
