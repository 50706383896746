import React, { useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material';

// Example questions data. Replace this with your imported questions.
const questions = [
  {
    question: 'What is the capital of France?',
    options: ['Paris', 'London', 'Berlin', 'Madrid'],
    correctAnswer: 'Paris'
  },
  {
    question: 'What is 2 + 2?',
    options: ['3', '4', '5', '6'],
    correctAnswer: '4'
  }
];

function Gamify() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);

  // Ensure questions array is valid before proceeding.
  if (!questions || questions.length === 0) {
    return (
      <Container>
        <Typography variant="h4" align="center" style={{ marginTop: '20px' }}>
          No questions available.
        </Typography>
      </Container>
    );
  }

  const handleNext = () => {
    if (selectedOption === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setSelectedOption('');
    } else {
      setShowScore(true);
    }
  };

  return (
    <Container>
      {showScore ? (
        <Typography variant="h4" align="center" style={{ marginTop: '20px' }}>
          Your Score: {score} / {questions.length}
        </Typography>
      ) : (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">{questions[currentQuestion].question}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {questions[currentQuestion].options.map((option, index) => (
                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!selectedOption} // Disable next button until an option is selected
            >
              {currentQuestion < questions.length - 1 ? 'Next' : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default Gamify;
