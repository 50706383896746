// import crypto from 'crypto';

// const crypto_orig_createHash = crypto.createHash;
// crypto.createHash = algorithm => crypto_orig_createHash(algorithm == "md4" ? "md5" : algorithm);

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
// import TypographyUsage from "./Typo";
// import App from "./App";

import Dashboardmain from "./Dashboardmain";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


root.render(
  <div>
    <Dashboardmain />
    
  </div>
);
