import React from 'react';
import { getVideos } from './Adminfiles/Vdetails';
import { Typography, Grid } from '@mui/material';

const Videos = () => {
  const videos = getVideos();

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {videos.length > 0 ? (
        videos.map((video, index) => (
          <Grid item xs={12} key={index}>
            <Typography variant="h6" component="h2">
              {video.topic}
            </Typography>
            <iframe
              width="560"
              height="315"
              src={video.link}
              title={video.topic}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </Grid>
        ))
      ) : (
        <Typography variant="h6" component="h2">
          No videos available
        </Typography>
      )}
    </Grid>
  );
};

export default Videos;
