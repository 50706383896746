import React, { useState } from "react";
import Videos from "./Videos";
import DVideos from "./Adminfiles/DVideos";
import { Grid, Box } from "@mui/material";

const MainComponent = () => {
  const [videoData, setVideoData] = useState({
    topic: "Difference between Interpreter and Compiler",
    videoLink: "https://www.youtube.com/embed/_C5AHaS1mOA?si=X4CMR3spFS4esR-1",
  });

  const handleVideoChange = ({ topic, videoLink }) => {
    setVideoData({
      topic,
      videoLink: videoLink.replace("watch?v=", "embed/"),
    });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            <Videos topic={videoData.topic} videoLink={videoData.videoLink} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            <DVideos onVideoChange={handleVideoChange} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainComponent;
