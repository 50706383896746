// import crypto from 'crypto';

// const crypto_orig_createHash = crypto.createHash;
// crypto.createHash = algorithm => crypto_orig_createHash(algorithm == "md4" ? "md5" : algorithm);

import React from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import VideoApp from "./VideoApp";
import Code from "./Code";
import Links from "./Links";
import Books from "./Books";
const handleVideoAppclick = () => {
  ReactDOM.render(<VideoApp />, document.getElementById("itlfpages"));
};
const handleCodeclick = () => {
  ReactDOM.render(<Code />, document.getElementById("itlfpages"));
};
const handleLinksclick = () => {
  ReactDOM.render(<Links />, document.getElementById("itlfpages"));
};
const handleBooksclick = () => {
  ReactDOM.render(<Books />, document.getElementById("itlfpages"));
};
const Preclassroom = () => {
  return (
    <div id="content">
      <Grid container spacing={4} sx={{ maxWidth: 800 }}>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Videos
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleVideoAppclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Code
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleCodeclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Links
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleLinksclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Books
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleBooksclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Preclassroom;
