// import crypto from 'crypto';

// const crypto_orig_createHash = crypto.createHash;
// crypto.createHash = algorithm => crypto_orig_createHash(algorithm == "md4" ? "md5" : algorithm);

import React from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import Description from "./Description2";
import References from "./References";
import Assignments from "./Assignments";
import Analysis from "./Analysis";
// import { QueryBox } from "./QueryBox";
const handleDescriptionclick = () => {
  ReactDOM.render(<Description />, document.getElementById("itlfpages"));
};
const handleReferencesclick = () => {
  ReactDOM.render(<References />, document.getElementById("itlfpages"));
};
const handleAssignmentsclick = () => {
  ReactDOM.render(<Assignments />, document.getElementById("itlfpages"));
};
const handleQueryBoxclick = () => {
  ReactDOM.render(<Analysis />, document.getElementById("itlfpages"));
};
const Classroom = () => {
  return (
    <div id="content">
      <Grid container spacing={4} sx={{ maxWidth: 800 }}>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Description
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleDescriptionclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                References
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleReferencesclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Assignments
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleAssignmentsclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                QueryBox
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleQueryBoxclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Classroom;
