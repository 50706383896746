import React, { useState } from 'react';
import { addVideo } from './Vdetails';
import { TextField, Button, Grid, Typography } from '@mui/material';

const Dvideos = () => {
  const [topic, setTopic] = useState('');
  const [link, setLink] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addVideo(topic, link);
    setTopic('');
    setLink('');
    alert('Video added successfully!');
  };

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Add YouTube Video
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <TextField
            label="Topic Name"
            variant="outlined"
            fullWidth
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <TextField
            label="YouTube Link"
            variant="outlined"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Add Video
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default Dvideos;
