// import crypto from 'crypto';

// const crypto_orig_createHash = crypto.createHash;
// crypto.createHash = algorithm => crypto_orig_createHash(algorithm == "md4" ? "md5" : algorithm);

import React from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import Preclassroom from "./Preclassroom";
import Classroom from "./Classroom";

import SReflection from "./SReflection";
import Gamify from "./Gamify";
const handlepreclassroomclick = () => {
  ReactDOM.render(<Preclassroom />, document.getElementById("itlfpages"));
};
const handleclassroomclick = () => {
  ReactDOM.render(<Classroom />, document.getElementById("itlfpages"));
};
const handlereflectionsclick = () => {
  ReactDOM.render(<SReflection />, document.getElementById("itlfpages"));
};
const handlegamifyclick = () => {
  ReactDOM.render(<Gamify />, document.getElementById("itlfpages"));
};
const App = () => {
  return (
    <div id="content">
      <Grid container spacing={4} sx={{ maxWidth: 800 }}>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Pre-Classroom
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handlepreclassroomclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Class Room
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handleclassroomclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Reflections
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handlereflectionsclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 4, borderRadius: "10px", width: 300 }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Gamify
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={handlegamifyclick}
                variant="contained"
                size="medium"
                fullWidth
              >
                Enter
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
